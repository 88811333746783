import { ref } from '@vue/composition-api'

export default function config() {
  const formBtnObj = ref()

  const draftBtn = {
    title: 'Save as Draft',
    isLoading: false,
    actionKey: 'draft_progress',
    color: '',
    disabledKeys: {
      isCreateForm: false,
      firstState: true,
    },
    redirectUrl: {
      firstState: 'sub-rent-request-for-quote',
    },
  }

  const voidBtn = {
    title: 'Void',
    isLoading: false,
    actionKey: 'draft_void',
    color: 'danger',
    disabledKeys: {
      isCreateForm: true,
      firstState: true,
    },
    redirectUrl: {
      firstState: 'sub-rent-request-for-quote',
    },
  }

  const printBtn = {
    title: 'Print',
    isLoading: false,
    actionKey: 'print',
    color: '',
    icon: 'LPrintIcon',
    disabledKeys: {
      isCreateForm: true,
      firstState: true,
    },
    redirectUrl: {
      firstState: 'home',
    },
  }

  const saveEmailBtn = {
    title: 'Send by Email',
    isLoading: false,
    actionKey: 'save_email',
    color: '',
    disabledKeys: {
      isCreateForm: true,
      firstState: true,
    },
    redirectUrl: {
      firstState: 'home',
    },
  }

  const receivedBtn = {
    title: 'Quote Received',
    isLoading: false,
    actionKey: 'invoice_progress',
    color: 'success',
    disabledKeys: {
      isCreateForm: true,
      firstState: true,
    },
    redirectUrl: {
      firstState: 'home',
    },

  }

  formBtnObj.value = {
    firstState: Array.call(null, voidBtn, draftBtn, printBtn, saveEmailBtn, receivedBtn),
  }

  return { formBtnObj, PRINT_ACTION: printBtn.actionKey, VOID_ACTON: voidBtn.actionKey }
}
