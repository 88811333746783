<template>
  <div id="form-header">
    <error-alert
      :fields="{}"
      :error="onError"
    />
    <b-form>
      <validation-observer ref="subRentQuoteForm">

        <!--   Order information     -->
        <order-information />

        <!--   Dispatch And Return    -->
        <dispatch-and-return />

        <!--   Sub rental period    -->
        <sub-rental-period />

        <!--   Stock items-->
        <stock-items />

        <!--   Form bottom     -->
        <form-bottom
          :form-state="formState"
          :form-ref="$refs"
          :is-create-form="isCreateForm"
        />
      </validation-observer>
    </b-form>
  </div>
</template>

<script>
import { BForm } from 'bootstrap-vue'
import ErrorAlert from '@/views/components/ErrorAlert.vue'
import { ValidationObserver } from 'vee-validate'
import OrderInformation
from './OrderInformation.vue'
import DispatchAndReturn
from './DispatchAndReturn.vue'
import SubRentalPeriod from './SubRentalPeriod.vue'
import StockItems from './StockItems.vue'
import FormBottom from './form-submit-buttons/FormBottom.vue'
import config from '../config'

export default {
  name: 'CreateForm',
  components: {
    ErrorAlert,
    BForm,
    ValidationObserver,
    OrderInformation,
    StockItems,
    DispatchAndReturn,
    SubRentalPeriod,
    FormBottom,
  },
  props: {
    isCreateForm: {
      type: Boolean,
      default: false,
    },
    formState: {
      type: String,
      default: 'firstState',
    },
  },
  data() {
    return {
      theSupplierId: this.$route.query?.supplier_id,
    }
  },
  computed: {
    quote() {
      return this.$store.state[this.MODULE_NAME].quote
    },
    onError() {
      return this.$store.state[this.MODULE_NAME].onError
    },
  },
  created() {},
  methods: {},
  setup() {
    const { quoteStatusBar, MODULE_NAME, CREATE_PAGE_TITLE } = config()

    return {
      MODULE_NAME,
      quoteStatusBar,
      CREATE_PAGE_TITLE,
    }
  },
}
</script>
