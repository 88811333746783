<template>
  <div class="d-flex mt-2 pb-1 justify-content-between">
    <div>
      <b-button
        class="cancelBtn font-medium-1 font-weight-bolder"
        variant="outline-primary"
        @click="$router.back()"
      >
        <feather-icon
          icon="LCancelIcon"
          size="16"
        />
        {{ $t('Cancel') }}
      </b-button>
    </div>
    <div class="d-flex">
      <b-overlay
        v-for="(item, index) in buttonsList"
        :key="item.title"
        :show="item.isLoading"
        rounded
        opacity="0.6"
        spinner-small
        :spinner-variant="item.color ? item.color : 'secondary'"
        class="d-inline-block ml-1"
      >
        <b-button
          :variant="item.color ? item.color : 'secondary'"
          class="font-medium-1 font-weight-bold px-3"
          :disabled="item.isLoading || disableButton(item.actionKey)"
          @click="handleSubmit(index,item.actionKey)"
        >
          <feather-icon
            v-show="printIcon(item.actionKey)"
            icon="LPrintIcon"
            size="16"
          />
          {{ item.title }}
        </b-button>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import { BButton, BOverlay } from 'bootstrap-vue'
import jsonToFormData from '@/libs/jsonToFormData'
import { mapGetters } from 'vuex'
import { printContent } from '@/views/main/orders/components/helpers'
import config from './libs'
import mainConfig from '../../config'

export default {
  name: 'FormBottom',
  components: {
    BOverlay,
    BButton,
  },
  props: {
    formState: {
      type: String,
      default: 'firstState',
    },
    formRef: {
      type: Object,
      required: true,
    },
    isCreateForm: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('sub-rent', ['getState']),
    buttonsList() {
      return this.formBtnObj[this.formState]
    },
    isUpdate() {
      return this.$store.state[this.MODULE_NAME].order?.id
    },
    isVoidOrder() {
      return this.$store.state[this.MODULE_NAME].order?.status === 5
    },
    reRenderComponentKey() {
      return this.$store.state[this.MODULE_NAME].reRenderComponentKey
    },
  },
  created() {
  },
  methods: {
    printIcon(actionKey) {
      return this.buttonsList.find(i => i.actionKey === actionKey)?.icon
    },
    // eslint-disable-next-line consistent-return
    handleSubmit(index, actionKey) {
      const isSomeButtonLoading = this.buttonsList.some(i => i.isLoading)
      if (!isSomeButtonLoading && actionKey === 'print') {
        return this.printContent()
      } if (isSomeButtonLoading) return undefined

      this.buttonsList[index].isLoading = true

      const body = jsonToFormData({
        ...this.getState,
        action: this.buttonsList[index].actionKey,
        id: this.isUpdate,
        _method: actionKey !== 'draft_void' ? this.isUpdate && 'PUT' : undefined,
      })

      // eslint-disable-next-line no-nested-ternary
      this.sendNotification(this, body, `${this.MODULE_NAME}/${actionKey === this.VOID_ACTON ? 'voidSubRental' : this.isUpdate ? 'updateSubRental' : 'create'}`)
        .then(response => {
          const { data } = response.data
          this.redirect(actionKey, data.id)
          this.$store.commit(`${this.MODULE_NAME}/SET_ON_ERROR`, {})
        })
        .catch(err => {
          this.errorNotification(this, err)
          this.$store.commit(`${this.MODULE_NAME}/SET_ON_ERROR`, err.response?.data)
          const element = document.getElementById('form-header')
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' })
            element.focus()
          }
        })
        .finally(() => {
          this.buttonsList[index].isLoading = false
        })
    },
    disableButton(actionKey) {
      const disabledKeys = this.buttonsList.find(i => i.actionKey === actionKey)?.disabledKeys
      const isCustomerIdMissing = !this.getState?.customer_id
      const isCreateFormDisabled = this.isCreateForm && disabledKeys?.isCreateForm
      const isFormStateDisabled = disabledKeys?.[this.formState]

      return (this.isVoidOrder && actionKey !== this.PRINT_ACTION) || isCustomerIdMissing || (isCreateFormDisabled && isFormStateDisabled)
    },
    redirect(actionKey, id) {
      const redirectUrl = this.buttonsList.find(i => i.actionKey === actionKey)?.redirectUrl?.[this.formState]
      this.$store.commit(`${this.MODULE_NAME}/SET_RERENDER_COMPONENT_KEY`)
      // if (redirectUrl) {
      //   // query: { render: this.reRenderComponentKey.toString() }
      //   return this.$router.replace({ name: redirectUrl, params: { id } }).catch(this.emptyCallback)
      // }
      // return this.$router.push({ name: 'home-orders-sub-rent-list' })
    },
  },
  setup() {
    const { MODULE_NAME, model, emptyCallback } = mainConfig()
    const { formBtnObj, VOID_ACTON, PRINT_ACTION } = config()

    return {
      MODULE_NAME,
      model,
      formBtnObj,
      emptyCallback,
      printContent,
      PRINT_ACTION,
      VOID_ACTON,
    }
  },
}
</script>
